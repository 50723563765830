<template>
  <b-container fluid>
    <b-breadcrumb
      class="mt-3"
      :items="[
        ...$route.meta.breadcrumb,
        {
          text: `${event.name} [${event.status}]`,
          to: { name: 'EventDashboard', params: { eventID: eventID } }
        },
        {
          text: `咖啡豆管理頁`
        }
      ]"
    >
    </b-breadcrumb>
    <b-row>
      <b-col>
        <h1>咖啡豆附加資訊</h1>
      </b-col>
    </b-row>
    <b-row class="mt-3" align-h="between">
      <b-col cols="3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </b-col>

      <b-col cols="2">
        <b-form-select
          class="ml-2"
          v-model="perPage"
          :options="[
            { value: 5, text: '每頁 5 個豆子' },
            { value: 10, text: '每頁 10 個豆子' },
            { value: 20, text: '每頁 20 個豆子' }
          ]"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          hover
          :current-page="currentPage"
          :per-page="perPage"
          :items="BeanList"
          :fields="fields"
        >
          <template #cell(toolbox)="data">
            <div class="d-flex justify-content-center">
              <b-btn
                variant="outline-primary"
                size="sm"
                pill
                @click="editBeanData(data.item.id)"
                v-b-tooltip.hover
                title="編輯"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-btn>
              <!-- <b-btn
                class="ml-2"
                variant="danger"
                size="sm"
                pill
                v-b-tooltip.hover
                title="刪除"
              >
                <b-icon icon="trash"></b-icon>
              </b-btn> -->
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- 更新 豆子資料 -->
    <b-modal
      id="edit-modal"
      :title="'編輯 ' + ModalData.code + ' 豆子'"
      v-model="showModal"
      lazy
      @ok="AlertTargetData()"
    >
      <b-container>
        <b-row>
          <b-col>
            <label>
              送件者
              <b-form-input
                v-model="ModalData.name"
                type="text"
                autocomplete="off"
                placeholder="輸入 送件者"
              ></b-form-input>
            </label>
          </b-col>

          <b-col>
            <label>
              位置
              <b-form-input
                v-model="ModalData.location"
                type="text"
                autocomplete="off"
                placeholder="輸入 位置"
              ></b-form-input>
            </label>
          </b-col>

          <b-col>
            <label>
              處理法
              <b-form-input
                v-model="ModalData.process"
                type="text"
                autocomplete="off"
                placeholder="輸入 處理法"
              ></b-form-input>
            </label>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import { API } from "../store/api";
export default {
  name: "CoffeeBeanManagement",
  props: {
    eventID: {
      type: Number,
      require: true
    }
  },
  computed: {
    totalRows() {
      return this.BeanList.length;
    }
  },
  mounted() {
    this.dataUpdate();
  },
  methods: {
    async dataUpdate() {
      this.event = (await API.GetEvent(this.eventID)).events[0];
      let data = await API.GetAdminTargets(this.eventID);
      this.BeanList = data.targets.reverse();
    },
    editBeanData(beanID) {
      this.showModal = true;
      this.ModalData = this.BeanList.find(element => element.id === beanID);
    },
    async AlertTargetData() {
      await API.PUTAdminTargets(
        this.ModalData.id,
        this.ModalData.name,
        this.ModalData.location,
        this.ModalData.process
      );

      Object.keys(this.ModalData).forEach(key => {
        this.ModalData[key] = null;
      });

      await this.dataUpdate();
    }
  },
  data() {
    return {
      event: {},
      currentPage: 1,
      perPage: 5,
      showModal: false,
      ModalData: {
        id: null,
        code: null,
        name: null,
        location: null,
        process: null
      },
      fields: [
        {
          key: "code",
          label: "暗碼",
          sortable: false
        },
        {
          key: "name",
          label: "送件者",
          sortable: false
        },
        {
          key: "location",
          label: "位置",
          sortable: false
        },
        {
          key: "process",
          label: "處理法",
          sortable: false
        },
        {
          key: "created_at",
          label: "建立於",
          sortable: true
        },
        {
          key: "updated_at",
          label: "最後更新於",
          sortable: true
        },
        {
          key: "toolbox",
          label: "資料編輯",
          sortable: false
        }
      ],
      BeanList: []
    };
  }
};
</script>

<style></style>
